<template>
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-md-12">
        <b-card :title="$t('message.title.customerCreditDetail')">
          <b-form>
            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 my-1">
                {{ $t("message.machineIdFarm") }} :
                <br />

                <list-select :list="machineData" :selected-item="selectedMachine" placeholder="Select Machine ID"
                  option-value="machineId" option-text="name" @select="onSelectMachine" />
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-2 my-1">
                {{ $t("message.refNumber") }}
                <br />
                <b-form-input v-model="searchData.refNumber" type="number" />
              </div>

              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                {{ $t("message.latestCreditMonth") }} :
                <br />
                <date-time-picker v-model="searchData.datetime"></date-time-picker>
              </div>

              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4 my-4">
                <b-btn class="ml-1 mr-1" variant="primary" v-on:click="getCredits({ excel: false })">
                  <i class="fa fa-search"></i>&nbsp;{{ $t("message.search") }}
                </b-btn>

                <b-btn class="ml-1 mr-1" variant="success" v-on:click="getCredits({ excel: true })">
                  <i class="fa fa-download"></i>&nbsp;{{ $t("message.export") }}
                </b-btn>

                <!-- <b-btn class="ml-1 mr-1" variant="primary" v-on:click="checkCredits({ excel: false })">
                  insert
                </b-btn> -->

              </div>
            </div>
            <div class="row my-2">
              <div class="col-sm-6 col-md-5">
                <b-form-checkbox v-model="searchData.haveCredit">{{ $t('message.haveCredit') }}</b-form-checkbox>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-sm-6 col-md-3" v-if="summary.credit > 0">
                {{ $t('message.creditBalance') }} : {{ summary.credit | formatNumber }}
              </div>
              <div class="col-sm-6 col-md-3" v-if="summary.custoemr > 0">
                {{ $t('message.customerAll') }} : {{ summary.custoemr | formatNumber }}
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-sm-6 col-md-4" v-if="summary.credit > 0">
                {{ $t('message.creditDate') }} : {{ summary.informationDate }}
              </div>
            </div>
          </b-form>

          <div class="table-responsive">
            <table class="table-modal">
              <thead>
                <th style="text-align-last: center">{{ $t("message.No") }}</th>
                <th style="text-align-last: center">{{ $t("message.machineIdFarm") }}</th>
                <th style="text-align-last: center">{{ $t("message.refNumber") }}</th>
                <th style="text-align-last: right">{{ $t("message.creditBefore") }}</th>
                <!-- <th style="text-align-last: center">{{ $t("message.createdAt") }}</th> -->
                <th style="text-align-last: center">{{ $t("message.updatedAt") }}</th>
                <!-- <th style="text-align-last: center">{{ $t("message.detail") }}</th> -->
              </thead>

              <tbody v-if="rowData != []">
                <template v-for="items in rowData">
                  <tr>
                    <td style="text-align: center">{{ items.index }}</td>
                    <td style="text-align: center">{{ items.machineId }}</td>
                    <td style="text-align: center">{{ items.refNumber }}</td>
                    <td style="text-align: right">{{ items.credit | formatNumber }}</td>
                    <!-- <td style="text-align: center"><label style="width: 140px;">{{ items.createdAt | moment("YYYY-MM-DD HH:mm") }}</label></td> -->
                    <td style="text-align: center">
                      <label style="width: 140px;">
                        {{ (items.lastUseCreditAt || items.lastUseAt) | moment("YYYY-MM-DD HH:mm") }}
                      </label>
                      <!-- <label style="width: 140px;"> {{ items.lastUseCreditAt | moment("YYYY-MM-DD HH:mm") }}</label> -->
                      <!-- <label style="width: 140px;"> {{ items.lastUseAt | moment("YYYY-MM-DD HH:mm") }}</label> -->

                    </td>
                    <!-- <td style="text-align: center">
                      <b-btn style="width: 118px;" variant="warning" v-on:click="showModal(items)">{{
                          $t("message.detail")
                      }}</b-btn>
                    </td> -->
                  </tr>
                </template>
              </tbody>

              <tbody v-else>
                <tr>
                  <td colspan="5" style="text-align: center">No Data</td>
                </tr>
              </tbody>

            </table>
          </div>
          <br />
          <Page :total="summary.custoemr" :page-size="itemperPage" @on-change="changePage" :current="currentPage" />

          <!-- <b-pagination size="md" :total-rows="totalRows" v-model="pagination" :per-page="itemperPage"
            @input="pageChanged(pagination)"></b-pagination> -->
        </b-card>
      </div>
    </div>

    <b-modal ref="detailsModal" :title="`${$t('message.refNumber')} ${searchDataTrans.refNumber}`" size="lg">
      <div class="table-responsive">
        <table class="table-modal">
          <thead style="text-align-last: center">
            <!-- <th>id</th> -->
            <!-- <th>{{ $t("message.refNumber") }}</th> -->

            <th><label style="width: 120px;">{{ $t("message.date") }}</label></th>
            <th><label style="width: 120px;">{{ $t("message.product") }}</label></th>
            <th style="text-align-last: right">{{ $t("message.price") }}</th>
            <th style="text-align-last: right">{{ $t("message.insert") }}</th>
            <th style="text-align-last: right">{{ $t("message.credit") }}</th>
            <th style="text-align-last: right">{{ $t("message.changes") }}</th>
            <th style="text-align-last: right">{{ $t("message.creditAfter") }}</th>
          </thead>
          <tbody>
            <template v-for="(items, index, key) in modalData">
              <tr style="text-align: center">

                <td>{{ items.createdAt | moment("YYYY-MM-DD HH:mm") }}</td>
                <td style="text-align: left">
                  <template v-if="items.type === 'addCredit' && items.paymentType !== 'REFUND'">
                    {{ $t("message.addCredit") }}</template>
                  <template v-else-if="items.type === 'addCredit' && items.paymentType === 'REFUND'">
                    {{ $t("message.refund") }}
                  </template>
                  <template v-else-if="items.type === 'deductCredit'">{{ $t("message.deductCredit") }}</template>
                  <template v-else-if="items.product">{{ items.product.name }}</template>
                </td>
                <td style="text-align: right">{{ items.price }}</td>
                <td style="text-align: right">{{ items.acceptorMoney }}</td>
                <td style="text-align: right">{{ items.creditCMBefore }}</td>
                <td style="text-align: right">
                  <template v-if="items.creditCMAfter - items.creditCMBefore > 0">
                    +{{ items.creditCMAfter - items.creditCMBefore }}
                  </template>
                  <template v-else>{{ items.creditCMAfter - items.creditCMBefore }}</template>
                </td>
                <td style="text-align: right">{{ items.creditCMAfter }}</td>
              </tr>
            </template>
          </tbody>
        </table>
        <br />
        <b-pagination size="md" :total-rows="totalRows2" v-model="pagination2" :per-page="20"
          @input="pageChanged2(pagination2)" v-if="modalData.length !== 0"></b-pagination>
      </div>
    </b-modal>

    <b-modal ref="exportModal" :title="$t('message.summarize')" @hidden="closeExportModal()">
      <table class="table-modal">
        <tr>
          <td>{{ $t("message.refNumber") }} :</td>
          <td>{{ totalRows }} เบอร์</td>
        </tr>
      </table>
      <div slot="modal-footer" class="w-100">
        <download-excel class="btn btn-primary" :title="title" :data="exportData" :fields="exportField"
          :name="reportFilename">
          <i class="icon-docs"></i>
          Export
        </download-excel>
      </div>
    </b-modal>

    <b-modal ref="resetPasswordModal" :title="$t('message.detail')" size="md" @ok="resetPasswrod()"
      :ok-title="$t('message.confirm')">
      <div class="table-responsive">
        <table class="table-modal">
          <tr>
            <td colspan="2">
              <p style="color: red">** รหัสผ่านใหม่จะถูกส่งผ่าน sms ไปยังมือถือลูกค้า</p>
            </td>
          </tr>
          <tr>
            <td>{{ $t("message.refNumber") }}</td>
            <td>{{ resetPasswordData.refNumber }}</td>
          </tr>
          <tr>
            <td>{{ $t("message.machineIdFarm") }}</td>
            <td>{{ resetPasswordData.machineId }}</td>
          </tr>
          <tr>
            <td>{{ $t("message.credit") }}</td>
            <td>{{ resetPasswordData.value }}</td>
          </tr>
        </table>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import moment from 'moment'
import FileSaver from 'file-saver'
import webServices from '../../script'
import { ListSelect, ModelListSelect, MultiListSelect } from '../../custom_modules/search-select'
// import { ListSelect, ModelListSelect, MultiListSelect } from '../../custom_modules/search-select'
import DateTimePicker from "../../components/DateTimePicker/MonthCreditDetail";
//https://stackoverflow.com/questions/44538110/how-to-format-numbers-in-vuejs
var numeral = require('numeral')

Vue.filter('formatNumber', function (value) {
  return numeral(value).format('0,0') // displaying other groupings/separators is possible, look at the docs
})

export default {
  name: 'customercredits',
  components: {
    DateTimePicker,
    ModelListSelect,
    MultiListSelect,
    ListSelect,
  },
  data() {
    const role = window.localStorage.getItem('roleID')
    return {
      role: role,
      rowData: '',
      totalRows: 0,
      totalRows2: 0,
      totalCredit: 0,
      today: '',
      summary: {
        custoemr: 0,
        credit: 0,
        informationDate: '',
      },
      searchData_export: {},
      searchData: {
        // datetime: '',
        datetime: moment().endOf('month').toDate(),
        // datetime: [
        //   moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
        //   moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
        // ],
        haveCredit: false,
        page: 1,
        rows: 30
      },
      searchDataTrans: {
        page: 1,
        rows: 20
      },
      pagination: 0,
      pagination2: 0,
      currentPage: 1,
      itemperPage: 30,
      type: [
        { value: 'addCredit', text: 'เพิ่มเครดิต' },
        { value: 'deductCredit', text: 'หักเครดิต', disabled: role === 'admin' ? false : true },
        { value: 'transfer', text: 'โอนเครดิต' }
      ],
      creditData: {
        status: 'SUCCESS',
        // type: 'credit',
        details: {}
      },
      modalData: [],
      machineData: [],
      title: [],
      exportData: [],
      exportField: {},
      reportFilename: '',
      selectedMachine: [],
      resetPasswordData: {},
      file_name: 'Report',
      branch: '',
      startDate: '',
      endDate: '',
    }
  },
  methods: {
    changePage(PageNum) {
      // window.scrollTo(0, 0)
      this.searchData.page = PageNum
      this.getCredits(PageNum)
      // this.currentPage = PageNum
      // console.log('PageNum', PageNum)
    },

    checkCredits() {
      console.log('checkCredits')
      this.axios
        .get(`/creditsdetail/create`, { params: this.searchData })
        .then(res => {
          this.$Progress.finish()
          this.rowData = res.data.rows
          this.totalRows = res.data.count
          this.totalCredit = res.data.totalCredit
          this.today = moment().format('YYYY-MM-DD HH:mm:ss')

        })
        .catch(err => {
          this.$Progress.fail()
          this.$toast.error({
            title: 'ERROR',
            message: 'เกิดข้อผิดพลาด'
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
          console.log('error getCredits')
          console.log(err)
        })
    },

    showAlert(title, text, confirmButton) {
      // console.log('showAlert_noneSelectBrach')
      // console.log('title', title)
      // console.log('text', text)

      this.$swal({
        title: title,
        // text: text,
        html: '<div>' + text + '</div>',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: confirmButton
      })
    },

    checkAleart() {
      let title = ''
      let text = ''
      let confirmButton = ''
      let statusShowAleart = false

      // console.log('checkAleart')
      // console.log('this.searchData', this.searchData)

      if (this.searchData.machineId == null) {
        statusShowAleart = true
        window.localStorage.getItem('language') == 'th' ? text = 'กรุณาเลือกสาขา' : text = 'Please select a branch.'
      }

      if (this.searchData.datetime == null || this.searchData.datetime == '' || this.searchData.datetime == ' ') {
        statusShowAleart = true
        window.localStorage.getItem('language') == 'th' ? text = 'เครดิตล่าสุดของเดือน' : text = 'Please select the latest credit of the month.'
      }

      if (this.searchData.machineId == null && this.searchData.datetime == null || this.searchData.datetime == '' || this.searchData.datetime == ' ') {
        statusShowAleart = true
        window.localStorage.getItem('language') == 'th'
          ? text = 'กรุณาเลือก สาขา \n ' + 'เครดิตล่าสุดของเดือน\n'
          : text = 'Please select a branch, \n ' + 'The latest credit of the month.\n'
      }

      if (statusShowAleart == true) {
        // console.log('show aleat')
        if (window.localStorage.getItem('language') == 'th') {
          title = 'ไม่สามารถค้นหาข้อมูลได้'
          confirmButton = 'ปิด'
        } else {
          title = 'Cannot Searchable'
          confirmButton = 'Close'
        }
        this.showAlert(title, text, confirmButton)
      }

      return statusShowAleart
    },

    getCredits(opt = { excel: false }) {
      this.$Progress.start()
      var localStorageUser = JSON.parse(window.localStorage.getItem("users"))
      var datetimeRequest = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

      this.searchData.userName = localStorageUser.userName
      this.searchData.userRole = localStorageUser.role
      this.searchData.userType = localStorageUser.type
      this.searchData.userConfiglanguage = localStorageUser.config.language
      this.searchData.datetimeRequest = datetimeRequest

      // this.rowData = []
      // this.summary.custoemr = 0
      // this.summary.credit = 0
      // this.summary.informationDate = ''

      let statueShow = this.checkAleart()
      console.log('statueShow', statueShow)

      // console.log('this.searchData', this.searchData)
      if (this.searchData.refNumber != null) {
        this.searchData.page = 1
      }

      if (statueShow == false) {
        if (!opt.excel) {
          this.axios
            .get(`/creditsdetail`, { params: this.searchData })
            .then(res => {
              this.$Progress.finish()
              this.rowData = res.data.rows
              this.summary.custoemr = res.data.customer
              this.summary.credit = res.data.summaryCredit
              this.summary.informationDate = res.data.informationDate

              console.log('res.data', res.data)

              this.searchData_export.userNamet = this.searchData.userName
              this.searchData_export.userRole = this.searchData.userRole
              this.searchData_export.userType = this.searchData.userType
              this.searchData_export.userConfiglanguage = this.searchData.userConfiglanguage
              this.searchData_export.datetimeRequest = this.searchData.datetimeRequest
              this.searchData_export.datetime = this.searchData.datetime
              this.searchData_export.haveCredit = this.searchData.haveCredit
              this.searchData_export.machineId = this.searchData.machineId
              this.searchData_export.name = this.searchData.name
              this.searchData_export.page = this.searchData.page
              this.searchData_export.rows = this.searchData.rows

              // console.log('res.data', res.data)
              // console.log('this.summary', this.summary)
            })
            .catch(err => {
              this.$Progress.fail()
              this.$toast.error({
                title: 'ERROR',
                message: 'เกิดข้อผิดพลาด'
              })
              if (err.response.status === 401) {
                webServices.tokenExpire()
              }
              console.log('error getCredits')
              console.log(err)
            })
        } else {
          if (this.rowData.length > 0) {
            // export excel
            // console.log('export excel',)

            console.log('this.searchData_export', this.searchData_export)

            if (this.searchData_export.machineId.length > 0) {
              for (let i = 0; i < this.machineData.length; i++) {
                if (this.searchData_export.id === this.machineData[i].machineId) {
                  const machineName = this.machineData[i].name
                  // this.searchData.machineId = this.machineData[i].machineId
                  this.searchData_export.name = machineName.substr(9).replaceAll('\\r\\n', '')
                }
              }

              this.searchData_export.branch = this.searchData_export.machineId
            } else {
              this.searchData_export.branch = '...'
              this.searchData_export.name = '...'
            }

            this.searchData_export.today = moment().format('YYYY-MM-DD HH:mm:ss')
            this.axios({
              url: `/creditsdetail/download`, // File URL Goes Here
              method: 'GET',
              params: this.searchData_export,
              responseType: 'blob'
            }).then(response => {

              FileSaver.saveAs(response.data, `Report_Credit_Balance_${this.searchData_export.branch}_date_${moment(this.searchData_export.datetime).endOf('month').format('YYYY-MM-DD')}.xlsx`)
              this.$Progress.finish()
            })
          }

        }
      }

    },


    getMachine() {
      this.$Progress.start()
      webServices
        .getMachineList(window.localStorage.getItem('userId'))
        .then(res => {
          this.$Progress.finish()
          this.machineData = res.data
        })
        .catch(err => {
          this.$Progress.fail()
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
          this.$toast.error({
            title: 'ERROR',
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
          })
          console.log('error @machine')
          console.log(err)
        })
    },
    onSelectMachine(items, lastSelectItem) {
      // console.log('onSelectMachine')
      // console.log(items)
      this.selectedMachine = items
      this.searchData.machineId = items.machineId
      this.searchData.name = items.name.substr(9).replaceAll('\\r\\n', '')

      // this.searchData.machineId = this.selectedMachine.map(data => data.machineId)
      // this.searchData.name = this.selectedMachine.map((data) => data.name.substr(9).replaceAll('\\r\\n', ''))

      // console.log('this.searchData.machineId', this.searchData.machineId)
      // console.log('this.searchData.name', this.searchData.name)
    },
    pageChanged(page) {
      this.searchData.page = page
      this.getCredits()
    },
    pageChanged2(page) {
      // console.log(page)
      this.searchDataTrans.page = page
      this.getAlltransaction(this.searchDataTrans)
    },
    manageCredit() {
      var localStorageUser = JSON.parse(window.localStorage.getItem("users"))
      var datetimeRequest = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

      this.creditData.userName = localStorageUser.userName
      this.creditData.userRole = localStorageUser.role
      this.creditData.userType = localStorageUser.type
      this.creditData.userConfiglanguage = localStorageUser.config.language
      this.creditData.datetimeRequest = datetimeRequest

      this.axios
        .post(`/credits`, this.creditData)
        .then(res => {
          if (res.data.success) {
            this.$toast.success({
              title: 'SUCCESS',
              message: 'สำเร็จ !!'
            })
            this.creditData = {}
            this.getCredits()
          } else {
            const error = webServices.showError(res.data)
            this.$toast.error({
              title: 'ERROR',
              message: JSON.stringify(error)
            })
          }
        })
        .catch(err => {
          console.log('error managerCredit')
          console.log(err)
          this.$toast.error({
            title: 'ERROR',
            message: 'เกิดข้อผิดพลาด'
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },
    showModal(data) {
      this.searchDataTrans.refNumber = data.refNumber
      this.searchDataTrans.machineId = data.machineId
      this.searchDataTrans.credits = 'true'
      this.$refs.detailsModal.show()
      this.getAlltransaction(this.searchDataTrans)
    },
    showResetPasswordModal(data) {
      this.resetPasswordData = data
      this.$refs.resetPasswordModal.show()
    },
    getAlltransaction(data) {
      this.$Progress.start()
      webServices
        .getTransactionsReportNew(this.searchDataTrans)
        .then(res => {
          this.$Progress.finish()
          // console.log(res.data)
          this.modalData = res.data.rows
          this.totalRows2 = res.data.count
        })
        .catch(err => {
          this.$Progress.fail()
          console.log(err)
        })
    },
    closeExportModal() {
      // console.log('close')
      this.exportData.pop()
      this.$refs.exportModal.hide()
    },
    resetPasswrod() {
      webServices
        .resetPasswrod(this.resetPasswordData)
        .then(res => {
          if (res.data.success) {
            this.$toast.success({
              title: 'SUCCESS',
              message: 'สำเร็จ'
            })
          } else {
            const errorText = {
              errorCode: res.data.errorCode,
              errorText: res.data.errorText
            }
            this.$toast.error({
              title: 'ERROR',
              message: JSON.stringify(errorText)
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
  mounted() {
    // if (this.role !== "admin") {
    //   alert("คุณไม่มีสิทธิ์ในการเข้าชมหน้านี้");
    //   window.history.back();
    // } else {

    // this.getCredits()
    this.getMachine()
    // }
  }
}
</script>